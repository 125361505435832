import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Paper, Box, Typography, TextField, Checkbox, FormControlLabel } from "@mui/material";
import { H2, SubHeader } from "../../../styled-components/Typography";
import ColumnsChips from "./Editor/ColumnsChips";
import EmailForm from "./Editor/Form";
import TextEditor from "./Editor/Editor";
import { useSheetsExtension } from "./SheetsExtensionContext";
import { PrimaryButton } from "../../../styled-components/Buttons";
import EmailPreview from "./Email/EmailPreview";

function ActionConfig() {
  const location = useLocation();
  const navigate = useNavigate();
  const { columns, rows, userEmail, sheetsUrl, rowCount, accountId } = location.state || {};
  const { setEmailData, emailData, activeInputRef, editorRef } = useSheetsExtension();
  const [includeOptOut, setIncludeOptOut] = useState(true);
  const [previewEmails, setPreviewEmails] = useState([]);
  const [actionConfig, setActionConfig] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [csrfToken, setCsrfToken] = useState("");

  const defaultUnsubMessage = "If you'd like me to stop sending you emails, please";
  const defaultUnsubLinkText = "click here";

  

  const handleOptOutChange = (event) => {
    setIncludeOptOut(event.target.checked);
    if (!event.target.checked) {
      setEmailData('unsubMessage', '');
      setEmailData('unsubLink', '');
    } else {
      setEmailData('unsubMessage', emailData.unsubMessage || defaultUnsubMessage);
      setEmailData('unsubLink', emailData.unsubLink || defaultUnsubLinkText);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEmailData(name, value);
  };
  const handleReturn = () => {
    navigate('/action-selector-extension?email=' + userEmail + '&sheets_url=' + sheetsUrl );  // Navigate back to the selector
  };
  const handlePreviewEmails = () => {
    const bodyHtml = editorRef.current ? editorRef.current.root.innerHTML : '';

    const emailPayload = {
      subject: emailData.subject,
      to_name: emailData.toName,
      body_html: bodyHtml,
      from_name: emailData.fromName,
      to_address: emailData.toAddress,
      from_address: emailData.fromAddress,
      bcc: emailData.bcc,
      unsub_message: emailData.unsubMessage,
      unsub_link: emailData.unsubLink,
    };
    setActionConfig(emailPayload)
    const requestData = {
      email_payload: emailPayload,
      columns: columns,
      rows: rows,
    };

    fetch('https://postsheet.com/api/get-preview-extension', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrfToken
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('CSRF token not valid or not set.');
        }
        return response.json();
      })
      .then((data) => {
        setPreviewEmails(data);
        setShowPreview(true);
      })
      .catch((error) => {
        console.error('Error fetching preview emails:', error);
      });
  };

  const handleReturnToConfig = () => {
    setShowPreview(false);
  };

  const previewMessage = `${emailData.unsubMessage || defaultUnsubMessage} <a href="#">${emailData.unsubLink || defaultUnsubLinkText}</a>`;

  return (
    <Paper elevation={3} sx={{ p: 2, m: 1 }}>
      <PrimaryButton variant="contained" onClick={handleReturn} sx={{ mb: 2 }}>
        Return to Action Selector
      </PrimaryButton>
      {!showPreview ? (
        <>
          <H2 sx={{ fontSize: '1rem' }}>New Email Campaign</H2>
          <SubHeader sx={{ fontSize: '0.875rem' }}>Define your template</SubHeader>
          <Box sx={{ my: 1 }}>
            <Typography sx={{ fontSize: '0.875rem' }}>Use the buttons below to insert columns from your spreadsheet:</Typography>
            <ColumnsChips columns={columns} userEmail={userEmail} />
            <EmailForm userEmail={userEmail} columns={columns} />
            <TextEditor  />
            <Box sx={{ mt: 2 }}>
              <FormControlLabel
                control={<Checkbox checked={includeOptOut} onChange={handleOptOutChange} size="small" />}
                label="Include opt-out message"
                sx={{ fontSize: '0.875rem' }}
              />
              {includeOptOut && (
                <>
                  <TextField
                    label="Opt Out Message"
                    name="unsubMessage"
                    value={emailData.unsubMessage}
                    onChange={handleInputChange}
                    fullWidth
                    inputProps={{ maxLength: 100 }}
                    size="small"
                    sx={{ mt: 1 }}
                  />
                  <TextField
                    label="Opt Out Link Text"
                    name="unsubLink"
                    value={emailData.unsubLink}
                    onChange={handleInputChange}
                    fullWidth
                    inputProps={{ maxLength: 30 }}
                    size="small"
                    sx={{ mt: 1 }}
                  />
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h6" sx={{ fontSize: '0.875rem' }}>Preview:</Typography>
                    <Typography
                      dangerouslySetInnerHTML={{ __html: previewMessage }}
                      sx={{ backgroundColor: '#f9f9f9', p: 1.5, borderRadius: 1, fontSize: '0.875rem' }}
                    />
                  </Box>
                </>
              )}
            </Box>
            <PrimaryButton variant="contained" onClick={handlePreviewEmails}>
              Preview Emails
            </PrimaryButton>
          </Box>
        </>
      ) : (
        <Box>
          <PrimaryButton variant="contained" onClick={handleReturnToConfig} sx={{ mb: 2 }}>
            Return to Configuration
          </PrimaryButton>
          <EmailPreview emails={previewEmails} rowCount={rowCount} columns={columns} rows={rows} userEmail={userEmail}  actionConfig={actionConfig} sheetsUrl={sheetsUrl} accountId={accountId}/>
        </Box>
      )}
    </Paper>
  );
}

export default ActionConfig;
