import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Paper, Box, Typography, TextField, Checkbox, FormControlLabel } from "@mui/material";
import { H2, SubHeader } from "../../../styled-components/Typography";
import ColumnsChips from "./Editor/ColumnsChips";
import TwilioForm from "./Editor/TwilioForm";
import { useSheetsExtension } from "./SheetsExtensionContext";
import { PrimaryButton } from "../../../styled-components/Buttons";
import SmsPreview from "./Sms/SmsPreview";
import TextEditor from "./Editor/Editor";

function TwilioActionConfig() {
  const location = useLocation();
  const navigate = useNavigate();
  const { columns, rows, userEmail, sheetsUrl, accountId, rowCount } = location.state || {};
  const { setTwilioData, twilioData, activeInputRef, editorRef } = useSheetsExtension();
  const [includeOptOut, setIncludeOptOut] = useState(true);
  const [previewSms, setPreviewSms] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [csrfToken, setCsrfToken] = useState("");

  const defaultUnsubMessage = "If you'd like me to stop sending you messages, please";
  const defaultUnsubLinkText = "click here";

  useEffect(() => {
    if (columns && rows) {
      setTwilioData("columns", columns);
      setTwilioData("rows", rows);
    }
  }, [columns, rows, setTwilioData]);

  const handleOptOutChange = (event) => {
    setIncludeOptOut(event.target.checked);
    if (!event.target.checked) {
      setTwilioData('unsubMessage', '');
      setTwilioData('unsubLink', '');
    } else {
      setTwilioData('unsubMessage', twilioData.unsubMessage || defaultUnsubMessage);
      setTwilioData('unsubLink', twilioData.unsubLink || defaultUnsubLinkText);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTwilioData(name, value);
  };

  const handlePreviewSms = () => {
    const bodyHtml = editorRef.current ? editorRef.current.root.innerHTML : '';

    const smsPayload = {
      to_number: twilioData.toNumber,
      from_number: twilioData.fromNumber,
      sms_template: bodyHtml,
    };

    const requestData = {
      sms_payload: smsPayload,
      columns: columns,
      rows: rows,
    };

    fetch('https://postsheet.com/api/get-preview-sms-extension', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrfToken,
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('CSRF token not valid or not set.');
        }
        return response.json();
      })
      .then((data) => {
        setPreviewSms(data);
        setShowPreview(true);
      })
      .catch((error) => {
        console.error('Error fetching preview SMS:', error);
      });
  };

  const handleReturnToConfig = () => {
    setShowPreview(false);
  };
  const handleReturn = () => {
    navigate('/action-selector-extension?email=' + userEmail + '&sheets_url=' + sheetsUrl ); // Navigate back to the selector
  };
  const previewMessage = `${twilioData.unsubMessage || defaultUnsubMessage} <a href="#">${twilioData.unsubLink || defaultUnsubLinkText}</a>`;

  return (
    <Paper elevation={3} sx={{ p: 2, m: 1 }}>
      <PrimaryButton variant="contained" onClick={handleReturn} sx={{ mb: 2 }}>
        Return to Action Selector
      </PrimaryButton>
      {!showPreview ? (
        <>
          <H2 sx={{ fontSize: '1rem' }}>New SMS Campaign</H2>
          <SubHeader sx={{ fontSize: '0.875rem' }}>Define your template</SubHeader>
          <Box sx={{ my: 1 }}>
            <Typography sx={{ fontSize: '0.875rem' }}>Use the buttons below to insert columns from your spreadsheet:</Typography>
            <ColumnsChips columns={columns} />
            <TwilioForm columns={columns} />
            <TextEditor/>
            <Box sx={{ mt: 2 }}>
              <FormControlLabel
                control={<Checkbox checked={includeOptOut} onChange={handleOptOutChange} size="small" />}
                label="Include opt-out message"
                sx={{ fontSize: '0.875rem' }}
              />
              {includeOptOut && (
                <>
                  <TextField
                    label="Opt Out Message"
                    name="unsubMessage"
                    value={twilioData.unsubMessage}
                    onChange={handleInputChange}
                    fullWidth
                    inputProps={{ maxLength: 100 }}
                    size="small"
                    sx={{ mt: 1 }}
                  />
                  <TextField
                    label="Opt Out Link Text"
                    name="unsubLink"
                    value={twilioData.unsubLink}
                    onChange={handleInputChange}
                    fullWidth
                    inputProps={{ maxLength: 30 }}
                    size="small"
                    sx={{ mt: 1 }}
                  />
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h6" sx={{ fontSize: '0.875rem' }}>Preview:</Typography>
                    <Typography
                      dangerouslySetInnerHTML={{ __html: previewMessage }}
                      sx={{ backgroundColor: '#f9f9f9', p: 1.5, borderRadius: 1, fontSize: '0.875rem' }}
                    />
                  </Box>
                </>
              )}
            </Box>
            <PrimaryButton variant="contained" onClick={handlePreviewSms}>
              Preview SMS
            </PrimaryButton>
          </Box>
        </>
      ) : (
        <Box>
          <PrimaryButton variant="contained" onClick={handleReturnToConfig} sx={{ mb: 2 }}>
            Return to Configuration
          </PrimaryButton>
          <SmsPreview emails={previewSms} />
        </Box>
      )}
    </Paper>
  );
}

export default TwilioActionConfig;
