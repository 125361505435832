import React, { useState } from 'react';
import { Box, Button, Collapse, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import './CampaignStateDictionary.css'; // Import the CSS file

const CampaignStateDictionary = () => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // Dictionary of campaign states with their descriptions
  const campaignStates = [
    {
      stateClass: 'state-initializing',
      title: 'INITIALIZING',
      description: 'Your campaign is not running yet, but it will start in a few seconds.'
    },
    {
      stateClass: 'state-pending',
      title: 'PENDING',
      description: "The campaign is pending, but it hasn't failed. A campaign pending for too long will become a failed campaign."
    },
    {
      stateClass: 'state-scheduled',
      title: 'SCHEDULED',
      description: 'Your campaign is scheduled to run on a specific date and time.'
    },
    {
      stateClass: 'state-paused',
      title: 'PAUSED',
      description: 'Your campaign was running, but it was paused. This can happen if you paused it manually or due to failed items. Failed item details are displayed under the progress bar on the campaign details page.'
    },
    {
      stateClass: 'state-completed_with_fail',
      title: 'INCOMPLETE',
      description: "Your campaign completed, but some items failed. The failed item details are displayed under the progress bar on the campaign details page."
    },
    {
      stateClass: 'state-running',
      title: 'RUNNING',
      description: 'Your campaign is currently running, and emails or messages are being sent.'
    },
    {
      stateClass: 'state-cancelled',
      title: 'CANCELLED',
      description: 'You cancelled your campaign. A cancelled campaign cannot be restarted.'
    },
    {
      stateClass: 'state-completed',
      title: 'COMPLETED',
      description: 'Your campaign was completed successfully with no failures.'
    },
    {
      stateClass: 'state-failed',
      title: 'FAILED',
      description: 'Your campaign failed to run, or all items in the campaign failed.'
    }
  ];

  return (
    <Paper elevation={1} style={{ margin: '20px 0', padding: '20px' }}>
      <Button onClick={handleExpandClick} style={{ margin: 1, color: expanded ? 'black' : 'rgba(0, 0, 0, 0.6)' }}>
        {expanded ? 'Hide Campaign State Dictionary' : 'Show Campaign State Dictionary'}
      </Button>
      <Collapse in={expanded}>
        <TableContainer>
          <Table aria-label="Campaign State Dictionary">
            <TableHead>
              <TableRow>
                <TableCell><strong>State Class</strong></TableCell>
                <TableCell><strong>Explanation</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {campaignStates.map((state, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <span className={`state ${state.stateClass}`}>
                      {state.title}
                    </span>
                  </TableCell>
                  <TableCell>{state.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </Paper>
  );
};

export default CampaignStateDictionary;
