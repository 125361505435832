import React, { useState, useEffect } from "react";
import { Paper, Tabs, Tab, Box, TextField, Button, CircularProgress } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { H3 } from "../../../styled-components/Typography";
import { useNewCampaign } from "../../../contexts/NewCampaignContext";
import TemplateDetailsTab from "./TemplateDetailsTab";
import EditTemplateTab from "./EditTemplateTab";
import PreviewPanel from "./PreviewTemplate/PreviewTemplateTab"; // Import PreviewTemplateTab

const TemplateDetailsMain = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const {
    airtableApiKey, setEmailData, emailData, sendMessages, setAttachment, activeInputRef,
    userDataSourceChoices, isTemplateInitialized, templateData, setTemplateData, setIsTemplateInitialized,
    templateItemRows, setTemplateItemRows, sendEmails, setAirtableApiKey,
     fetchAirtableConfig
  } = useNewCampaign();
  const [template, setTemplate] = useState(null);
  const [attachmentName, setAttachmentName] = useState('')
  const [airtableKey, setAirtableKey] = useState('')
  const [jobItemRows, setJobItemRows] = useState([]);
  const [dataSourceAccount, setDataSourceAccount] = useState(null);
  const [actionAccount, setActionAccount] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [campaignName, setCampaignName] = useState("");
  const [isFetching, setIsFetching] = useState(false); // Track fetching status
  const [processedEmails, setProcessedEmails] = useState([]); // Add state for processed emails
  const [rowCount, setRowCount] = useState(0); // Add state for row count
  const [isTemplateFetched, setIsTemplateFetched] = useState(false); // Add state to track if the template is fully fetched

  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));

          break;
        }
      }
    }
    return cookieValue;
  }
  const csrftoken = getCookie("csrftoken");
  const recordError = async (errorMessage, apiUrl, requestBody) => {
    try {
      const errorRecordUrl = 'https://postsheet.com/api/error-record';
      await fetch(errorRecordUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({
          error_message: errorMessage,
          api_url: apiUrl,
          request: requestBody,
        }),
      });
    }catch (err) {
      console.error('Failed to record the error:', err);
      navigate('/app/error', { state: { errorMessage: err.message } });
    }
  };

  const handleGoogleSheetIdChange = async (spreadsheetId, sheetId) => {

  
    const url = `https://docs.google.com/spreadsheets/d/${spreadsheetId}/edit#gid=${sheetId}`;

    
    const data = {
      sheets_url: url,
    };

    try {
      const response = await fetch(
        "https://postsheet.com/api/google/get-gsheets",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
          credentials: "include",
          body: JSON.stringify(data),
        }
      );
  
      if (response.status === 401) {
        const responseData = await response.json();
        if (responseData.redirect) {
          alert('Your session has expired. You will be redirected to the login page for security reasons.');
          window.location.href = '/signin';  // Redirect to the login page
          return;
        }
      }

      if (!response.ok) {
        throw new Error("Failed to fetch data from Google Sheets");
      }
  
      const responseData = await response.json();

      setTemplateItemRows(responseData.gsheets_data.rows);
      setJobItemRows(responseData.gsheets_data.rows);
      return responseData;
    } catch (error) {
      console.error("Error changing Google Sheets ID:", error);
      recordError(error.message, 'https://postsheet.com/api/google/get-gsheets', { sheets_url: url });
      navigate('/app/error', { state: { errorMessage: error.message } });
    }
  };

  const fetchFileDetails = async (file) => {
    let url;
    try {
       
       
        if (file.id) {
      
            url = `https://postsheet.com/api/user-files/details/${file.id}`;
        } else if (file.size && file.user_id && file.name) {
        
            url = `https://postsheet.com/api/user-files/details-by-attributes/${file.size}/${file.name}/${file.user_id}`;
        } else {
            throw new Error("Insufficient file attributes to fetch details");
        }

        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                // Add authentication headers if required, e.g., Authorization
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const details = await response.json();


        setTemplateItemRows(details.rows);
        setJobItemRows(details.rows);
        return details; // Return the file details
    } catch (error) {
        console.error("Error fetching file details:", error);
        recordError(error.message, url, { file });
        navigate('/app/error', { state: { errorMessage: error.message } }); // Handle error appropriately
    }
};

  const fetchAirtableData = async (baseId, tableId) => {

let airtable_config;
let airtable_key;
airtable_config = await fetchAirtableConfig()

airtable_key = airtable_config.airtable_config.api_key ? airtable_config.airtable_config.api_key : airtable_config.airtable_config.token;

    if (!baseId || !tableId) {
      return;
    }

    setAirtableKey(airtable_key)
    try {
      const response = await fetch("https://postsheet.com/api/accounts/get-airtable-data", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({
          base_id: baseId,
          table_name: tableId,
          api_key: airtable_key,
        })
      });
  
     
  
      const data = await response.json();
      
      setTemplateItemRows(data.airtable_data.rows);
      setJobItemRows(data.airtable_data.rows);
      return data;
    } catch (error) {
      console.error('Error fetching airtable data:', error);
      setTemplateItemRows(null);
      recordError(error.message, "https://postsheet.com/api/accounts/get-airtable-data", { base_id: baseId, table_name: tableId, api_key: airtableApiKey });
      navigate('/app/error', { state: { errorMessage: error.message } });
    }
  };

  useEffect(() => {
    const fetchData = async () => {

      try {
        const response = await fetch(`https://postsheet.com/api/templates/${uid}`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
      
        if(data.attachment_name) {
          setAttachmentName(data.attachment_name)
        }

        if (!isTemplateInitialized) {
  
          setTemplate(data.template);
          setTemplateData('fromAddress', data.template.action_config.from_address || '');
          setTemplateData('fromName', data.template.action_config.from_name || '');
          setTemplateData('toAddress', data.template.action_config.to_address || '');
          setTemplateData('toName', data.template.action_config.to_name || '');
          setTemplateData('subject', data.template.action_config.subject || '');
          setTemplateData('emailTemplate', data.template.action_config.body_html || '');
          setTemplateData('bcc', data.template.action_config.bcc || '');
          setTemplateData('unsubMessage', data.template.action_config.unsub_message || '');
          setTemplateData('unsubLink', data.template.action_config.unsub_link || '');
          setIsTemplateInitialized(true);
        }
   
        setDataSourceAccount(data.data_source_account);
        setActionAccount(data.action_account);
        setDataLoaded(true);
        setIsTemplateFetched(true); // Set the template as fully fetched
      } catch (error) {
        console.error('Failed to fetch template:', error);
        recordError(error.message, `https://postsheet.com/api/templates/${uid}`, { uid });
        navigate('/app/error', { state: { errorMessage: error.message } });
      }
    };

    fetchData();
  }, [uid]);

  useEffect(() => {

    const fetchProcessedEmails = async () => {
      if (!isTemplateFetched || !template || !csrftoken) return;


      let fetchedData = null;
      let templateItemRows;

      if (template.data_source_type === 'google.gsheets') {

        fetchedData = await handleGoogleSheetIdChange(template.data_source_config.spreadsheet_id, template.data_source_config.sheet_id);

        templateItemRows = fetchedData.gsheets_data.rows;
      } else if (template.data_source_type === 'airtable.airtable') {
   
        fetchedData = await fetchAirtableData(template.data_source_config.base_id, template.data_source_config.table_id);

        templateItemRows = fetchedData.airtable_data.rows;
      } else {

        fetchedData = await fetchFileDetails(template.data_source_config);

        templateItemRows = fetchedData.rows;
      }
      

      if (!templateItemRows.length) return;
    
      const jobData = {
        data_source_type: template.data_source_type,
        data_source_config: template.data_source_config,
        data_source_columns: template.data_source_columns,
        action_type: template.action_type,
        action_config: template.action_config,
        action_account_id: template.action_account_id,
        job_item_rows: templateItemRows,
        campaign_name: campaignName,
        data_source_account_id: template.data_source_account_id,
      };


      try {
        const response = await fetch('https://postsheet.com/api/jobs/fetch-processed-emails', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken,
          },
          body: JSON.stringify(jobData),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        
        setProcessedEmails(result.processed_emails);
        setRowCount(templateItemRows.length);
      } catch (error) {
        console.error('Error fetching processed emails:', error);
        recordError(error.message, 'https://postsheet.com/api/jobs/fetch-processed-emails', { jobData });
        navigate('/app/error', { state: { errorMessage: error.message } });
      }
    };

    fetchProcessedEmails();
  }, [isTemplateFetched, template, csrftoken, campaignName]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const goToPreviousTab = () => {
    setTabValue(prevTab => Math.max(prevTab - 1, 0));
  };

  const sendJobData = async () => {
 
    
    const jobSettings = {
      // add relevant job settings here
    };

    let fetchedData = null;
    let jobData = {};
    setIsFetching(true); // Start fetching

    if (template.data_source_type === 'google.gsheets') {

      fetchedData = await handleGoogleSheetIdChange(template.data_source_config.spreadsheet_id, template.data_source_config.sheet_id);
 
      jobData = {
        data_source_type: template.data_source_type,
        data_source_config: template.data_source_config,
        data_source_columns: template.data_source_columns,
        action_type: template.action_type,
        action_config: template.action_config,
        action_account_id: template.action_account_id,
        job_item_rows: fetchedData.gsheets_data.rows, // Use updated state
        scheduled_date_time: null, // Add scheduled date if needed
        campaign_name: campaignName,
        job_settings: jobSettings,
        data_source_account_id: template.data_source_account_id,
        bcc_list: [], // Add BCC list if needed
      };
    } else if (template.data_source_type === 'airtable.airtable') {
   
    
      fetchedData = await fetchAirtableData(template.data_source_config.base_id, template.data_source_config.table_id);
      jobData = {
        data_source_type: template.data_source_type,
        data_source_config: template.data_source_config,
        data_source_columns: template.data_source_columns,
        action_type: template.action_type,
        action_config: template.action_config,
        action_account_id: template.action_account_id,
        job_item_rows: fetchedData.airtable_data.rows, // Use updated state
        scheduled_date_time: null, // Add scheduled date if needed
        campaign_name: campaignName,
        job_settings: jobSettings,
        data_source_account_id: template.data_source_account_id,
        bcc_list: [], // Add BCC list if needed
      };
    
    } else {
     
     
      fetchedData = await fetchFileDetails(template.data_source_config);
      jobData = {
        data_source_type: template.data_source_type,
        data_source_config: template.data_source_config,
        data_source_columns: template.data_source_columns,
        action_type: template.action_type,
        action_config: template.action_config,
        action_account_id: template.action_account_id,
        job_item_rows: fetchedData.rows, // Use updated state
        scheduled_date_time: null, // Add scheduled date if needed
        campaign_name: campaignName,
        job_settings: jobSettings,
        data_source_account_id: template.data_source_account_id,
        bcc_list: [], // Add BCC list if needed
      };

    }

    // Wait for the state updates
    if (fetchedData && template.data_source_type === 'google.gsheets') {

      await new Promise((resolve) => {
        setTemplateItemRows(fetchedData.gsheets_data.rows);
        setJobItemRows(fetchedData.gsheets_data.rows);
        resolve();
      });
    } else if(fetchedData && template.data_source_type === 'airtable.airtable') {

      await new Promise((resolve) => {
        setTemplateItemRows(fetchedData.airtable_data.rows);
        setJobItemRows(fetchedData.airtable_data.rows);
        resolve();
      });
    } else {
 
      await new Promise((resolve) => {
        setTemplateItemRows(fetchedData.rows);
        setJobItemRows(fetchedData.rows);
        resolve();
      });
    }

    // Use updated states
    const updatedTemplateItemRows = templateItemRows;
    const updatedJobItemRows = jobItemRows;


    const formData = new FormData();
    formData.append("data", JSON.stringify(jobData));
    if (template.attachment) {
      formData.append("attachment", template.attachment);
    }
 
    try {
      const response = await fetch('https://postsheet.com/api/jobs/create-job-job-items', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'X-CSRFToken': csrftoken,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();

      const uid = result.job.uid;
      setIsFetching(false); // End fetching
      navigate(`/app/details/${uid}`, { state: { totalItems: jobData.job_item_rows.length, job: result.job, enableRealTimeParam: true } });
      if(jobData.action_type == "gmail.send-email") {

        await sendEmails(result.processed_emails, result.job.id, result.job.action_config.attached_file_s3_key);
      }else {

        await sendMessages(result.processed_emails, result.job.id)
      }
      
    } catch (error) {
      setIsFetching(false); // End fetching in case of error
      console.error('Error creating job and job items or sending emails:', error);
      recordError(error.message, 'https://postsheet.com/api/jobs/create-job-job-items', { jobData });
      navigate('/app/error', { state: { errorMessage: error.message } });
    }
  };

  if (!dataLoaded || isFetching) {
    return (
      <Paper sx={{ width: "100%", overflow: "hidden", padding: '1rem' }}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      </Paper>
    ); // Or any other loading indicator
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper elevation={3} sx={{ padding: "1rem", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <TextField
          label="Campaign Name"
          value={campaignName}
          onChange={(e) => setCampaignName(e.target.value)}
          sx={{ width: '30%' }}
        />
        <Button variant="contained" color="primary" onClick={sendJobData}>
          Run Campaign
        </Button>
      </Paper>
      <Paper elevation={3} sx={{ padding: "1rem", marginTop: "1rem" }}>
        <Tabs value={tabValue} onChange={handleChange} aria-label="template details tabs">
          <Tab label="Details" />
          <Tab label="Edit" />
          <Tab label="Preview" />
        </Tabs>
        {tabValue === 0 && <TemplateDetailsTab template={template} dataSourceAccount={dataSourceAccount} actionAccount={actionAccount} dataSourceChoices={userDataSourceChoices} airtableApiKey={airtableKey} attachmentName = {attachmentName} />}
        {tabValue === 1 && <EditTemplateTab template={template} setTemplate={setTemplate} dataSourceChoices={userDataSourceChoices} user_id={template.user_id} attachmentName = {attachmentName} /> }
        {tabValue === 2 && <PreviewPanel emails={processedEmails} row_count={rowCount} action_type={template.action_type}/>} {/* Pass the processed emails and row count */}
        
        {/* Back Button */}
        <Button
          onClick={goToPreviousTab}
          disabled={tabValue === 0} // Disable if it's the first tab
          style={{ marginTop: '1rem' }}
        >
          Back
        </Button>
      </Paper>
    </Box>
  );
};

export default TemplateDetailsMain;
