import React, { useState } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  TablePagination 
} from '@mui/material';
import { H2 } from '../../../styled-components/Typography';

interface Column {
  title: string;
  field: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  render?: (data: any) => React.ReactNode;
}

interface ReusableTableProps {
  data: any[];
  columns: Column[];
  noDataText: string;
  title?: string;
  padding?: boolean;
}

const ReusableTable: React.FC<ReusableTableProps> = ({ data, columns, noDataText, title, padding }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // default to 10 rows

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // reset to first page when rows per page change
  };

  const tableContainerStyle = padding ? { padding: '1.5rem' } : {};

  // Calculate the data for the current page
  const startIndex = page * rowsPerPage;
  const currentPageData = data.slice(startIndex, startIndex + rowsPerPage);

  return (
    <>
      <TableContainer component={Paper} style={tableContainerStyle}>
        {title && <H2>{title}</H2>}
        <Table aria-label="customizable table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index} align={column.align || 'left'}>
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPageData.length > 0 ? (
              currentPageData.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {columns.map((column, colIndex) => (
                    <TableCell key={colIndex} align={column.align || 'left'}>
                      {column.render ? column.render(row) : row[column.field]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {noDataText}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          count={data.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 15, 20]}
        />
      </TableContainer>
    </>
  );
};

export default ReusableTable;
