import React from "react";
import { Box, Typography } from "@mui/material";

const SelectedEmail = ({ selectedEmail }) => {
  // Optionally, simplify or remove any additional sanitization logic if you're sure the data is safe.
  const sanitizeBodyHtml = (bodyHtml) => {
    // Minimal sanitization, just ensuring line breaks or basic replacements as needed
    return bodyHtml.replace(/<br>\s*&nbsp;/g, '<br>')
                   .replace(/<p>/g, '<div>')
                   .replace(/<\/p>/g, '</div>');
  };

  const sanitizedBodyHtml = sanitizeBodyHtml(selectedEmail.body_html);

  return (
    <>
      <Box sx={{ padding: 2 }}>
      <Typography>
        <strong>From:</strong> {selectedEmail.from_name} &lt;{selectedEmail.from_address}&gt;
      </Typography>
      <Typography>
        <strong>To:</strong> {selectedEmail.to_name} &lt;{selectedEmail.to_address}&gt;
      </Typography>
      <Typography>
        <strong>Subject:</strong> {selectedEmail.subject}
      </Typography>
        <Typography
          sx={{
            marginTop: 2,
            overflowWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap'
          }}
          dangerouslySetInnerHTML={{ __html: sanitizedBodyHtml }}
        />
      </Box>
    </>
  );
};

export default SelectedEmail;
