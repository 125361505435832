import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import SelectedSms from './SelectedSms';
import { primaryColor } from '../../../../styled-components/StyleSettings';
import { SecondaryButton } from '../../../../styled-components/Buttons';

const SmsPreview = ({ smsPreviews }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSendToAppScript = () => {
    const selectedSms = smsPreviews[selectedTab];
    if (!selectedSms) {
      console.error('Error: selectedSms is null or undefined');
      return;
    }

    window.parent.postMessage(
      {
        action: 'send_modal_content',
        selectedSms: {
          from_number: selectedSms.from_number || '',
          to_number: selectedSms.to_number || '',
          sms_template: selectedSms.sms_template || '',
        }
      },
      '*'
    );
  };

  const smsTabs = Array.from({ length: Math.min(smsPreviews.length, 3) }, (_, i) => `Preview ${i + 1}`);
  const selectedSms = smsPreviews[selectedTab];

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          TabIndicatorProps={{
            style: {
              backgroundColor: primaryColor,
            }
          }}
          sx={{
            '.MuiTab-root': { color: 'rgba(0, 0, 0, 0.6)' },
            '.Mui-selected': { color: 'black !important' }
          }}
        >
          {smsTabs.map((tab, index) => (
            <Tab key={index} label={tab} />
          ))}
        </Tabs>
      </Box>
      <Box sx={{ p: 2, overflowWrap: 'break-word', wordBreak: 'break-word' }}>
        <SelectedSms selectedSms={selectedSms} />
        <SecondaryButton variant="contained" color="primary" onClick={handleSendToAppScript} sx={{ mt: 2 }}>
          Detailed Preview
        </SecondaryButton>
      </Box>
    </>
  );
};

export default SmsPreview;
