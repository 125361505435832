import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// Create the AuthContext
const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);

// Provider component to wrap around the app
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [subscriptionData, setSubscriptionData] = useState({})
  const  [expiredData, setExpiredData] = useState(true)
  const [id, setId] = useState(null);
  const [uid, setUid] = useState(null);
  const navigate = useNavigate(); 
  const verifyToken = async () => {
    const url = 'https://postsheet.com/api/check-auth';
    try {
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Falha na autenticação: ${errorData.detail || 'Erro desconhecido'}`);
      }

      const data = await response.json();

      setIsAuthenticated(data.isAuthenticated);
      setId(data.encrypted_id);
      setUid(data.encrypted_uid);
      setSubscriptionData(data.data.can_still_run);
      if(!data.data.can_still_run) 
      {
        navigate('/exceeded');
      }
      if(data.data.expired) 
        {
          navigate('/expired');
        }
      setExpiredData(data.data.expired)
    } catch (error) {
      console.error('Erro ao verificar a autenticação:', error.message);
      setIsAuthenticated(false);
    }
  };
  
  useEffect(() => {
    verifyToken().finally(() => setLoading(false));
  }, []);
  // useEffect(() => {
  //   const fetchSubscriptionData = async () => {
  //     console.log("Entrou em fetch Subscription Data")
  //     try {
  //       const url = 'https://postsheet.com/api/subscription-status'
  //       const response = await fetch(url, {
  //         method: 'GET',
  //         credentials: 'include',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //       });
  
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       const data = await response.json();
  //       console.log("Subscription Data", data)
  //       setSubscriptionData(data);
       
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchSubscriptionData()
  // }, [subscriptionData]);
  return (
    <AuthContext.Provider value={{ isAuthenticated, loading, id, uid, verifyToken, subscriptionData , expiredData}}>
      {children}
    </AuthContext.Provider>
  );
};
