import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const JobsContext = createContext();

export const useJobs = () => useContext(JobsContext);

export const JobsProvider = ({ children }) => {
  const navigate = useNavigate()
  const [data, setData] = useState({
    active_jobs: [],
    scheduled_jobs: [],
    past_jobs: [],
    accounts: [],
    total_jobs: 0,
    loading_active: true,
    loading_scheduled: true,
    loading_past: true
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [batch, setBatch] = useState(0);
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));

          break;
        }
      }
    }
    return cookieValue;
  }
  const csrftoken = getCookie("csrftoken");
  const recordError = async (errorMessage, apiUrl, requestBody) => {
    try {
      const errorRecordUrl = 'https://postsheet.com/api/error-record';
      await fetch(errorRecordUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({
          error_message: errorMessage,
          api_url: apiUrl,
          request: requestBody,
        }),
      });
    }catch (err) {
      console.error('Failed to record the error:', err);
      navigate('/app/error', { state: { errorMessage: err.message } });
    }
  };
  const fetchJobs = async (batch) => {
   
    try {
      const url = `https://postsheet.com/api/jobs?batch=${batch}`;
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
       
      setData((prevData) => ({
        ...prevData,
        active_jobs: [...prevData.active_jobs, ...result.active_jobs],
        scheduled_jobs: [...prevData.scheduled_jobs, ...result.scheduled_jobs],
        past_jobs: [...prevData.past_jobs, ...result.past_jobs],
        accounts: result.accounts,
        total_jobs: result.total_jobs,
        loading_active: result.loading_active,
        loading_scheduled: result.loading_scheduled,
        loading_past: result.loading_past,
      }));
      setBatch((prevBatch) => prevBatch + 1);
    } catch (error) {
      setError(error);
      recordError(error.message, 'https://postsheet.com/api/jobs', null);
      navigate('/app/error', { state: { errorMessage: error.message } });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
  
      setLoading(true);
      await fetchJobs(0); // Fetch the first batch
    };
    fetchData();
  }, []);

  // Fetch the next batch of jobs if there are more jobs to fetch
  useEffect(() => {
    
    if (data.active_jobs.length + data.scheduled_jobs.length + data.past_jobs.length < data.total_jobs) {
      fetchJobs(batch); // Fetch the next batch when needed
    }
  }, [data.active_jobs, data.scheduled_jobs, data.past_jobs, batch]);

  return (
    <JobsContext.Provider value={{ ...data, loading, error }}>
      {children}
    </JobsContext.Provider>
  );
};
