import React, { useEffect } from 'react';
import { Box, Typography, Paper, CircularProgress } from "@material-ui/core";
import { useJobs } from "../../../contexts/JobsContext";
import ReusableTable from "../../../components/common/ui/ReusableTable";
import { PiWarningCircleBold } from "react-icons/pi";
import { myCampaignsColumns, scheduledCampaignsColumns, pastCampaignsColumns } from "./columns";
import { H2, H3TableHeader } from "../../../styled-components/Typography";
import CampaignStateDictionary from './CampaignStateDictionary';

const UserJobs = () => {
  const { active_jobs, scheduled_jobs, past_jobs, loading, loading_active, loading_past, loading_scheduled } = useJobs();


  return (
    <Paper style={{ width: "100%", padding: "20px" }}>
      <Box style={{ width: "100%" }}>
        <H2>My Campaigns</H2>
        <br />
        <br />
        <CampaignStateDictionary/>

        {/* Active Campaigns */}
        {loading_active ? (
          <>
            <br />
            <Paper style={{ padding: 2, marginBottom: 3 }}>
              <H3TableHeader>Active Campaigns</H3TableHeader>
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
              </Box>
            </Paper>
          </>
        ) : active_jobs.length > 0 ? (
          <>
            <br />
            <Paper style={{ padding: 2, marginBottom: 3 }}>
              <H3TableHeader>Active Campaigns</H3TableHeader>
              <ReusableTable
                data={active_jobs}
                columns={myCampaignsColumns}
                noDataText="You do not have any active campaigns."
              />
            </Paper>
          </>
        ) : (
          <>
            <br />
            <Paper style={{ padding: 1, marginBottom: 3 }}>
              <H3TableHeader>Active Campaigns</H3TableHeader>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ marginLeft: '0.5rem' }}>
                  <PiWarningCircleBold size={18} />
                </div>
                <div style={{ marginLeft: '0.75rem' }}>
                  <Typography style={{ lineHeight: '2.5rem' }}>
                    You do not have any active campaigns.
                  </Typography>
                </div>
                <br />
              </div>
            </Paper>
          </>
        )}

        {/* Scheduled Campaigns */}
        {loading_scheduled ? (
          <>
            <br />
            <Paper style={{ padding: 2, marginBottom: 3 }}>
              <H3TableHeader>Scheduled Campaigns</H3TableHeader>
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
              </Box>
            </Paper>
          </>
        ) : scheduled_jobs.length > 0 ? (
          <>
            <br />
            <Paper style={{ padding: 2, marginBottom: 3 }}>
              <H3TableHeader>Scheduled Campaigns</H3TableHeader>
              <ReusableTable
                data={scheduled_jobs}
                columns={scheduledCampaignsColumns}
                noDataText="You do not have any scheduled campaigns."
              />
            </Paper>
          </>
        ) : (
          <>
            <br />
            <Paper style={{ padding: 2, marginBottom: 3 }}>
              <H3TableHeader>Scheduled Campaigns</H3TableHeader>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ marginLeft: '0.5rem' }}>
                  <PiWarningCircleBold size={18} />
                </div>
                <div style={{ marginLeft: '0.75rem' }}>
                  <Typography style={{ lineHeight: '2.5rem' }}>
                    You do not have any scheduled campaigns.
                  </Typography>
                </div>
                <br />
              </div>
            </Paper>
          </>
        )}

        {/* Past Campaigns */}
        {loading_past ? (
          <>
            <br />
            <Paper style={{ padding: 2, marginBottom: 3 }}>
              <H3TableHeader>Past Campaigns</H3TableHeader>
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
              </Box>
            </Paper>
          </>
        ) : past_jobs.length > 0 ? (
          <>
            <br />
            <Paper style={{ padding: 2, marginBottom: 3 }}>
              <H3TableHeader>Past Campaigns</H3TableHeader>
              <ReusableTable
                data={past_jobs}
                columns={pastCampaignsColumns}
                noDataText="You do not have any past campaigns."
              />
            </Paper>
          </>
        ) : (
          <>
            <br />
            <Paper style={{ padding: 2, marginBottom: 3 }}>
              <H3TableHeader>Past Campaigns</H3TableHeader>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ marginLeft: '0.5rem' }}>
                  <PiWarningCircleBold size={18} />
                </div>
                <div style={{ marginLeft: '0.75rem' }}>
                  <Typography style={{ lineHeight: '2.5rem' }}>
                    You do not have any past campaigns.
                  </Typography>
                </div>
                <br />
              </div>
            </Paper>
          </>
        )}
      </Box>
    </Paper>
  );
};

export default UserJobs;
