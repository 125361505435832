import { formatDistanceStrict, parseISO, format } from 'date-fns';
import React from 'react';
import { LinkSimple } from '../../../styled-components/Typography';

// Helper function to format dates
const formatDate = (date) => {
  return formatDistanceStrict(date, new Date(), { addSuffix: true });
};

// Columns for "My Campaigns"
export const myCampaignsColumns = [
  {
    title: 'NAME',
    field: 'name',
    render: (rowData) => (
      <LinkSimple
        to={{
          pathname: `/app/details/${rowData.uid}`,
        }}
        state={{
          job: rowData,
          totalItems: rowData.stats_total,
          isMenu: true,
          enableRealTimeParam: rowData.state === 'running' || rowData.state === 'initializing'
        }}
      >
        {rowData.name}
      </LinkSimple>
    )
  },
  { 
    title: 'DATA SOURCE', 
    field: 'data_source_type', 
    render: (rowData) => {
      switch (rowData.data_source_type) {
        case 'csv':
          return 'Csv';
        case 'xls':
          return 'Xls';
        case 'google.gsheets':
          return 'Google Sheets';
        case 'airtable.airtable':
          return 'Airtable';
        default:
          return rowData.data_source_type;
      }
    }
  },
  { 
    title: 'ACTION', 
    field: 'action_type', 
    render: (rowData) => {
      switch (rowData.action_type) {
        case 'gmail.send-email':
          return 'Send Email';
        case 'twilio.send-sms':
          return 'Send Sms';
        default:
          return rowData.action_type;
      }
    }
  },
  { title: 'COUNT', field: 'stats_total', align: 'right' },
  { 
    title: 'STATUS', 
    field: 'state', 
    render: (rowData) => {
      let displayText = rowData.state;
      
      // Check if the state is 'completed_with_fail' and change the display text accordingly
      if (rowData.state === 'completed_with_fail') {
        displayText = 'INCOMPLETED';
      }
  
      return <span className={`state-${rowData.state}`}>{displayText}</span>;
    }
  },
  { title: 'STARTED', field: 'start_date_time', render: (rowData) => rowData.start_date_time ? <span title={rowData.start_date_time}>{formatDate(new Date(rowData.start_date_time))}</span> : null },
];

// Columns for "Scheduled Campaigns"
export const scheduledCampaignsColumns = [
  // The columns are similar to "My Campaigns", with 'Scheduled Date' instead of 'Started'
  ...myCampaignsColumns.filter(column => column.title !== 'STARTED'),
  { 
    title: 'SCHEDULED DATE', 
    field: 'scheduled_date_time', 
    render: (rowData) => rowData.scheduled_date_time 
      ? <span title={rowData.scheduled_date_time}>{format(parseISO(rowData.scheduled_date_time), "MM-dd-yyyy HH:mm:ss")}</span> 
      : null 
  },
];

// Columns for "Past Campaigns"
export const pastCampaignsColumns = [
  ...myCampaignsColumns,
  { title: 'COMPLETED', field: 'end_date_time', render: (rowData) => rowData.end_date_time && rowData.state === 'completed' ? <span title={rowData.end_date_time}>{formatDate(new Date(rowData.end_date_time))}</span> : null },
  // Adding columns for Link and Email Tracking as necessary
  {
    title: 'LINK TRACKING',
    field: 'link_tracking',
    render: (rowData) => rowData.link_tracking ? `${rowData.link_tracking.unhover}% clicked (${rowData.link_tracking.hover} clicked)` : 'No tracking',
  },
  {
    title: 'EMAIL TRACKING',
    field: 'items_opened',
    render: (rowData) => rowData.items_opened && rowData.items_sent ? `${Math.round((rowData.items_opened / rowData.items_sent) * 100)}% opened (${rowData.items_opened} opened)` : 'No tracking',
  },
];
