import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

const ModalSelectedEmail = () => {
  const [emailData, setEmailData] = useState({
    from_name: '',
    from_address: '',
    to_name: '',
    to_address: '',
    subject: '',
    body_html: '',
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setEmailData({
      from_name: params.get('from_name') || '',
      from_address: params.get('from_address') || '',
      to_name: params.get('to_name') || '',
      to_address: params.get('to_address') || '',
      subject: params.get('subject') || '',
      body_html: params.get('body_html') || '',
    });
  }, []);

  useEffect(() => {
    // Send the height of the content to the parent window
    const height = document.body.scrollHeight;
    window.parent.postMessage({ action: 'resize', height: height }, '*');
  }, [emailData]);

  const sanitizeBodyHtml = (bodyHtml) => {
    return bodyHtml.replace(/<br>\s*&nbsp;/g, '<br>')
                   .replace(/<p>/g, '<div>')
                   .replace(/<\/p>/g, '</div>');
  };

  const sanitizedBodyHtml = sanitizeBodyHtml(emailData.body_html);

  return (
    <Box sx={{ padding: 2 }}>
      <Typography>
        <strong>From:</strong> {emailData.from_name} &lt;{emailData.from_address}&gt;
      </Typography>
      <Typography>
        <strong>To:</strong> {emailData.to_name} &lt;{emailData.to_address}&gt;
      </Typography>
      <Typography>
        <strong>Subject:</strong> {emailData.subject}
      </Typography>
      <Typography
        sx={{
          marginTop: 2,
          overflowWrap: 'break-word',
          wordBreak: 'break-word',
          whiteSpace: 'pre-wrap'
        }}
        dangerouslySetInnerHTML={{ __html: sanitizedBodyHtml }}
      />
    </Box>
  );
};

export default ModalSelectedEmail;
