import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Button } from '@mui/material';
import SelectedEmail from './SelectedEmail';
import { primaryColor } from '../../../../styled-components/StyleSettings';
import { SecondaryButton, PrimaryButton } from '../../../../styled-components/Buttons';
import { useNavigate } from 'react-router-dom';
import { useSheetsExtension } from '../SheetsExtensionContext';

const EmailPreview = ({ emails, rowCount, columns, rows, userEmail, sheetsUrl, actionConfig, accountId }) => {
    const {csrfTokenGlobal} = useSheetsExtension()
    const [selectedTab, setSelectedTab] = useState(0);
    const navigate = useNavigate()
    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== "") {
          const cookies = document.cookie.split(";");
    
          for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === name + "=") {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
    
              break;
            }
          }
        }
        return cookieValue;
      }
      const csrftoken = getCookie("csrftoken");
     
    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    };
    const handleSendToRecipients = async () => {
        // Extract spreadsheet_id and sheet_id from sheetsUrl
        const spreadsheetIdMatch = sheetsUrl.match(/spreadsheets\/d\/([a-zA-Z0-9-_]+)/);
        const sheetIdMatch = sheetsUrl.match(/gid=([0-9]+)/);
        

        const spreadsheet_id = spreadsheetIdMatch ? spreadsheetIdMatch[1] : null;
        const sheet_id = sheetIdMatch ? sheetIdMatch[1] : null;

        if (!spreadsheet_id || !sheet_id) {
            console.error('Error: Invalid Google Sheets URL format');
            return;
        }
        console.log("Account Id on the SEND EMAILS", accountId)
        const payload = {
            data_source_type: 'google.gsheets',
            user_email: userEmail,
            data_source_config: { spreadsheet_id, sheet_id },
            data_source_columns: columns,
            action_type: 'gmail.send-email',
            action_config: actionConfig,
            job_settings: {
                save_template: false,
                stop_on_failed_items: false,
                detailed_warm_up: false,
                warm_up_setting: 0,
                track_emails: false,
                track_links: false,
            },
            action_account_id: accountId,
            job_item_rows: rows,
        };
        console.log("PAYLOAAD", payload)
        // Send request to backend API
        try {
            const response = await fetch('https://postsheet.com/api/jobs/create-job-job-items-extension', {
                method: 'POST',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json',
                     'X-CSRFToken': csrfTokenGlobal                 
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
              
            } else {
                console.error('Failed to create job and job items');
            }
            const result = await response.json();
            console.log("result", result)
            const uid = result.job.uid;
            navigate(`/extension/details/${uid}`, { state: { totalItems: rows.length, job: result.job, enableRealTimeParam: true } });
            sendEmails(result.processed_emails, result.job.id, result.django_user_id);
            // if(!scheduledDate) {
        
               
            //   } else {
            //     navigate(`/app/details/${uid}`, { state: { totalItems: jobItemRows.length, job: result.job, enableRealTimeParam: false } });
            //   }
        } catch (error) {
            console.error('Error sending request:', error);
        }
    };
    const sendEmails = async (emails, job_id, django_user_id) => {
        console.log("Django user ID WHEN REACHING SEND EMAILS FUNCTION", django_user_id)
        const batchSize = 5;
        let allBatchesSent = true;
    
        for (let i = 0; i < emails.length; i += batchSize) {
            const emailBatch = emails.slice(i, i + batchSize);
            const isFirstBatch = i === 0;
            const isLastBatch = (i + batchSize) >= emails.length;
            const formData = new FormData();
            formData.append('data', JSON.stringify({
                emails: emailBatch,
                job_id: job_id,
                is_first_batch: isFirstBatch,
                is_last_batch: isLastBatch,
                django_user_id: django_user_id
            }));
            // if (attachment) {
            //     formData.append('attachment', attachment);
            // }
    
            try {
                const response = await fetch('https://postsheet.com/api/emails/send-extension', {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'X-CSRFToken': csrfTokenGlobal                 
                    },
                    body: formData,
                });
    
                if (!response.ok) {
                    throw new Error('Failed to send emails');
                }
    
                const result = await response.json();
    
                if (result.error) {
                    throw new Error(result.error);
                }
                await new Promise(resolve => setTimeout(resolve, 500));
            } catch (error) {
                console.error('Error sending emails:', error);
                allBatchesSent = false;
                break;  // Exit the loop on error
            }
        }
    
      
    };
    
    const handleSendToAppScript = () => {
        if (!selectedEmail) {
            console.error('Error: selectedEmail is null or undefined');
            return;
        }
    
        window.parent.postMessage(
            {
                action: 'send_modal_content',
                selectedEmail: {
                    from_name: selectedEmail.from_name || '',
                    from_address: selectedEmail.from_address || '',
                    to_name: selectedEmail.to_name || '',
                    to_address: selectedEmail.to_address || '',
                    subject: selectedEmail.subject || '',
                    body_html: selectedEmail.body_html || ''
                }
            },
            '*'
        );
    };

    const emailTabs = Array.from({ length: Math.min(emails.length, 3) }, (_, i) => `Preview ${i + 1}`);
    const selectedEmail = emails[selectedTab];

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={selectedTab}
                    onChange={handleChangeTab}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: primaryColor,
                        }
                    }}
                    sx={{
                        '.MuiTab-root': { color: 'rgba(0, 0, 0, 0.6)' },
                        '.Mui-selected': { color: 'black !important' }
                    }}
                >
                    {emailTabs.map((tab, index) => (
                        <Tab key={index} label={tab} />
                    ))}
                </Tabs>
            </Box>
            <Box sx={{ p: 2, overflowWrap: 'break-word', wordBreak: 'break-word' }}>
                <SelectedEmail selectedEmail={selectedEmail} />
                <PrimaryButton onClick={handleSendToRecipients} sx={{ mt: 2 }}>Send to {rowCount} recipients</PrimaryButton>
                <SecondaryButton variant="contained" color="primary" onClick={handleSendToAppScript} sx={{ mt: 1 }}>
                    Detailed Preview
                </SecondaryButton>
            </Box>
        </>
    );
};

export default EmailPreview;
