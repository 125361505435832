import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
} from "@mui/material";
import { ProgressBar } from "../../../../../styled-components/ProgressBar";
import { SubHeader } from "../../../../../styled-components/Typography";
import { useJobDetailsExtension } from "../../DetailsExtensionContext";
import { PrimaryButton } from "../../../../../styled-components/Buttons";
import { useNavigate } from "react-router-dom";

const EmailProgressBar = ({
  totalItemsCount,
  statsSucceeded,
  statsFailed,
  jobId,
  actionType,
}) => {
  const navigate = useNavigate();
  const { enableRealTime, jobState } = useJobDetailsExtension();
  const [failedEmails, setFailedEmails] = useState([]);
  const [succeededItems, setSucceededItems] = useState(statsSucceeded);
  const [failedItems, setFailedItems] = useState(statsFailed);
  const [progress, setProgress] = useState(0);
  const [intervalDelay, setIntervalDelay] = useState(500);
  const [retryCount, setRetryCount] = useState(0);

  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  const csrftoken = getCookie("csrftoken");

  const recordError = async (errorMessage, apiUrl, requestBody) => {
    try {
      const errorRecordUrl = "https://postsheet.com/api/error-record-extension";
      await fetch(errorRecordUrl, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({
          error_message: errorMessage,
          api_url: apiUrl,
          request: requestBody,
        }),
      });
    } catch (err) {
      console.error("Failed to record the error:", err);
      navigate("/app/error", { state: { errorMessage: err.message } });
    }
  };

  useEffect(() => {
    let interval;
    const url = `https://postsheet.com/api/progress/${jobId}/`;

    const fetchProgress = async () => {
      try {
        const response = await fetch(url, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setSucceededItems(data.succeeded_items);
        setFailedItems(data.failed_items);
        setProgress(data.progress);
        setFailedEmails(data.failed_emails);

        if (data.progress >= 50) {
          setIntervalDelay(2000);
        }

        if (data.status !== "running" && data.status !== "initializing") {
          clearInterval(interval);
        }
      } catch (error) {
        recordError(error.message, url, null);
        setRetryCount((prev) => prev + 1);
        const backoffDelay = Math.min(1000 * 2 ** retryCount, 30000);
        setIntervalDelay(backoffDelay);
        navigate("/app/error", { state: { errorMessage: error.message } });
      }
    };

    if (jobState === "running" || jobState === "initializing") {
      interval = setInterval(fetchProgress, intervalDelay);
    } else {
      fetchProgress();
    }

    return () => clearInterval(interval);
  }, [intervalDelay, jobState, jobId, retryCount]);

  const createTableHTML = () => {
    // Inline styling for table to mimic MUI look:
    const tableStyle = `
      font-family: Arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    `;
    const thStyle = `
      border-bottom: 1px solid #ccc;
      text-align: left;
      padding: 8px;
      background: #f5f5f5;
    `;
    const tdStyle = `
      border-bottom: 1px solid #ccc;
      text-align: left;
      padding: 8px;
    `;

    let tableHead = "";
    let tableBody = "";

    if (actionType === "twilio.send-sms") {
      tableHead = `
        <tr>
          <th style="${thStyle}">To Number</th>
          <th style="${thStyle}">Twilio Error Code</th>
          <th style="${thStyle}">Error Details</th>
          <th style="${thStyle}">Twilio Error Details Url</th>
        </tr>
      `;
      tableBody = failedEmails.map(sms => `
        <tr>
          <td style="${tdStyle}">${sms.to_number}</td>
          <td style="${tdStyle}">${sms.error_code}</td>
          <td style="${tdStyle}">${sms.error_details}</td>
          <td style="${tdStyle}">
            ${sms.url_details ? `<a href="${sms.url_details}" target="_blank">${sms.url_details}</a>` : ""}
          </td>
        </tr>
      `).join("");
    } else {
      tableHead = `
        <tr>
          <th style="${thStyle}">To Address</th>
          <th style="${thStyle}">To Name</th>
          <th style="${thStyle}">Error</th>
        </tr>
      `;
      tableBody = failedEmails.map(email => `
        <tr>
          <td style="${tdStyle}">${email.to_address}</td>
          <td style="${tdStyle}">${email.to_name}</td>
          <td style="${tdStyle}">${email.error_message}</td>
        </tr>
      `).join("");
    }

    return `
      <div style="font-family: Arial, sans-serif;">
        <h3 style="margin-bottom: 1rem;">Failed Items</h3>
        <table style="${tableStyle}">
          <thead>${tableHead}</thead>
          <tbody>${tableBody}</tbody>
        </table>
      </div>
    `;
  };

  const handleShowFailedModal = () => {
    const htmlString = createTableHTML();
    window.parent.postMessage({ action: 'send_failed_modal', html: htmlString }, '*');
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h4">{succeededItems}</Typography>
          <Typography variant="subtitle1">Sent</Typography>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h4">{totalItemsCount}</Typography>
          <Typography variant="subtitle1">Total</Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ width: "100%", mr: 1 }}>
            <ProgressBar
              sx={{ width: "100%" }}
              variant="determinate"
              value={progress}
            />
          </Box>
          <div>{`${progress}%`}</div>
        </Box>
      </Box>

      {failedEmails.length > 0 && (
        <>
          <Box sx={{ textAlign: "center", marginTop: "1.5rem" }}>
            <Typography variant="h4">{failedEmails.length}</Typography>
            <Typography variant="subtitle1">Failed</Typography>
          </Box>
          <Box sx={{ mt: 4 }}>
            <SubHeader>Failed Items</SubHeader>
            <PrimaryButton variant="contained" onClick={handleShowFailedModal}>
              View Failed Items
            </PrimaryButton>
          </Box>
        </>
      )}
    </>
  );
};

export default EmailProgressBar;
