import React, { useEffect, useState } from 'react';
import { TextField, Grid, Select, InputLabel, FormControl, MenuItem } from '@mui/material';
import { useSheetsExtension } from '../SheetsExtensionContext';

const TwilioForm = ({ columns, template }) => {
  const { activeInputRef, setTwilioTemplateData, twilioTemplateData } = useSheetsExtension();
  const [bccOpen, setBccOpen] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTwilioTemplateData(name, value);
  };

  useEffect(() => { 

  }, [twilioTemplateData]);

  const handleFocus = (event) => {
    activeInputRef.current = event.target;
  };

  return (
    <form noValidate autoComplete="off">
      <Grid container spacing={2} style={{ marginTop: '2rem' }}>
        <Grid item xs={6}>
          <TextField
            label="From Number"
            value={template ? template.action_config.from_number : twilioTemplateData.fromNumber}
            name="fromNumber"
            fullWidth
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
            onFocus={handleFocus}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="to-number-label">To Number</InputLabel>
            <Select
              labelId="to-number-label"
              value={template ? template.action_config.to_number : twilioTemplateData.toNumber}
              onChange={handleChange}
              onFocus={handleFocus}
              name="toNumber"
              required
            >
              {columns.map((column, index) => (
                <MenuItem key={index} value={`{{${column.name}}}`}>{`{{${column.name}}}`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};

export default TwilioForm;
