import React, { useEffect, useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import SelectedEmail from './SelectedEmail';
import { primaryColor } from '../../../../styled-components/StyleSettings';

const EmailPreview = ({ emails }) => {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    };
    useEffect(() => {
       
    })
    // Gera nomes de abas com base no número de e-mails, mas não mais do que três
    const emailTabs = Array.from({ length: Math.min(emails.length, 3) }, (_, i) => `Preview ${i + 1}`);

    const selectedEmail = emails[selectedTab]; // Determina o e-mail a ser exibido com base na aba selecionada

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={selectedTab}
                    onChange={handleChangeTab}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: primaryColor, // Sua cor personalizada para o indicador
                        }
                    }}
                    sx={{
                        '.MuiTab-root': { color: 'rgba(0, 0, 0, 0.6)' }, // cor padrão para todas as abas não selecionadas
                        '.Mui-selected': { color: 'black !important' } // força a cor preta na aba selecionada
                    }}
                >
                    {emailTabs.map((tab, index) => (
                        <Tab key={index} label={tab} />
                    ))}
                </Tabs>
            </Box>
            <SelectedEmail selectedEmail={selectedEmail} />
        </>
    );
}

export default EmailPreview;
