import React from "react";
import { Box, Typography } from "@mui/material";

const SelectedSms = ({ selectedSms }) => {
  const sanitizeMessage = (message) => {
    return message.replace(/<br>\s*&nbsp;/g, '<br>')
                  .replace(/<p>/g, '<div>')
                  .replace(/<\/p>/g, '</div>');
  };

  const sanitizedMessage = sanitizeMessage(selectedSms.sms_template);

  return (
    <Box sx={{ padding: 2 }}>
      <Typography>
        <strong>From:</strong> {selectedSms.from_number}
      </Typography>
      <Typography>
        <strong>To:</strong> {selectedSms.to_number}
      </Typography>
      <Typography
        sx={{
          marginTop: 2,
          overflowWrap: 'break-word',
          wordBreak: 'break-word',
          whiteSpace: 'pre-wrap'
        }}
        dangerouslySetInnerHTML={{ __html: sanitizedMessage }}
      />
    </Box>
  );
};

export default SelectedSms;
