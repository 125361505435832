import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Grid, Button, Slide, Paper, Box, CircularProgress } from '@mui/material';
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { useNewCampaign } from '../../../../contexts/NewCampaignContext';
import DataChoicePanels from './NewDataSource/DataChoicePanels';
import XlsUploadPanel from './DataSourceConfig/XlsUploadPanel';
import CsvUploadPanel from './DataSourceConfig/CsvUploadPanel';
import GoogleSheetsUploadPanel from './DataSourceConfig/GSheetsUploadPanel';
import AirtablePanel from './DataSourceConfig/AirtablePanel';
import UploadDialog from '../dialogs/UploadDialog';
import ActionSelector from '../ActionChoice/ActionChoice';
import { useAccounts } from '../../../../contexts/AccountsContext';
import ActionConfig from '../ActionConfig/ActionConfig';
import PreviewPanel from '../Preview/Preview';
import NewUploadDialog from '../dialogs/NewUploadDialog';
import PreviewSmsPanel from '../Preview/PreviewSms';
import { useAuth } from '../../../../contexts/AuthContext';
import TwilioActionConfig from '../ActionConfig/ActionSmsConfig';

function NewCampaign() {
  const location = useLocation();
  const {expiredData, subscriptionData} = useAuth();
  const navigate = useNavigate();
  const {
    currentStep,
    setCurrentStep,
    goToNextStep,
    goToPreviousStep,
    userDataSourceChoices,
    availableDataSourceChoices,
    selectedDataSource,
    handleDataSourceSelect,
    handleActionSelect,
    userActionTypes,
    cardStyle,
    actionConfig,
    googleSheetUrl,
    setGoogleSheetUrl,
    handleGoogleSheetUrlChange,
    imgStyle,
    uploadFile,
    checkFileExists,
    airtableBases,
    user,
    handlePreview,
    previewEmails,
    dataSourceConfig,
    selectedTableId,
    setSelectedBaseId,
    setSelectedTableId,
    actionType,
    loading,
    setLoading,
    dataSourceColumns,
    jobActionConfig,
    jobItemRows,
    setHighlightNextButton,
    highlightNextButton,
    updateActionConfig,
    updateDataSourceConfig,
    setDataSourceType,
    setSelectedDataSource,
    isToNextStep,
    setIsToNextStep
  } = useNewCampaign();

  const { handleAccountSelection } = useAccounts();
  const [selectedFile, setSelectedFile] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModalNew, setOpenModalNew] = useState(false);
  
  // Force step to 0 on page load or refresh
  useEffect(() => {
   
    const params = new URLSearchParams(location.search);
    const step = parseInt(params.get('step'), 10);
    if (step) {

    }
   
    if (!isToNextStep) {
      navigate(`${location.pathname}?step=0`);
    }
  }, [location.pathname, navigate]);

  // Set current step based on URL params
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const step = parseInt(params.get('step'), 10);

    if (step >= 0 && step <= 4 && !isToNextStep) {
      setCurrentStep(step);
    }
  }, [location.search, setCurrentStep]);

  // Update the URL whenever the step changes
  useEffect(() => {
  if (isToNextStep) {
    navigate(`?step=${currentStep}`);
  }
    setIsToNextStep(false)
  }, [currentStep, navigate]);

  const goToPreviousStepWithNavigation = () => {
    goToPreviousStep();
  };

  const useHandleCardAccountClick = (key) => {
    const path = handleAccountSelection(key);
    navigate(path);
  };

  const isNextButtonEnabled = () => {
    switch (currentStep) {
      case 1:
        const basicDataSourceCondition = (
          (selectedDataSource === 'google' && googleSheetUrl !== "" && actionConfig.columns?.length > 0) ||
          ((selectedDataSource === 'csv' || selectedDataSource === 'xls') && selectedFile && actionConfig.columns?.length > 0) ||
          (selectedDataSource !== 'google' && selectedDataSource !== 'csv' && selectedDataSource !== 'xls' && selectedTableId !== "" && actionConfig.columns?.length > 0)
        );
        
      // Ensure that actionConfig.columns exists and has at least one entry
      return basicDataSourceCondition && actionConfig.columns && actionConfig.columns.length > 0;
      case 2:
        return false;
      case 3:
        return true;
      default:
        return true;
    }
  };
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));

          break;
        }
      }
    }
    return cookieValue;
  }
  const csrftoken = getCookie("csrftoken");
  const recordError = async (errorMessage, apiUrl, requestBody) => {
    try {
      const errorRecordUrl = 'https://postsheet.com/api/error-record';
      await fetch(errorRecordUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({
          error_message: errorMessage,
          api_url: apiUrl,
          request: requestBody,
        }),
      });
    }catch (err) {
      console.error('Failed to record the error:', err);
      navigate('/app/error', { state: { errorMessage: err.message } });
    }
  };
  const goToNextStepWithConditions = async () => {
    if ((currentStep === 1 && selectedFile) || (currentStep === 1 && googleSheetUrl)) {
      try {
        if (selectedFile) {
          if (actionConfig.existing_file === true) {
            setOpenModal(true);
          } else {
            setOpenModalNew(true);
          }
        } else if (googleSheetUrl) {
          goToNextStep();
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        recordError(error.message, 'goToNextStepWithConditions', { currentStep, selectedFile, googleSheetUrl });

        navigate('/app/error', { state: { errorMessage: error.message } });
      }
    } else if (currentStep === 3) {
      await handlePreview(user);
      goToNextStep();
    } else {
      goToNextStep();
    }
  };

  const renderCurrentStep = () => {
    if (loading) {
      return (
        <Paper sx={{ width: "100%", overflow: "hidden", padding: '1rem' }}>
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
          </Box>
        </Paper>
      );
    }

    switch (currentStep) {
      case 0:
        return (
          <DataChoicePanels
            userDataSourceChoices={userDataSourceChoices}
            availableDataSourceChoices={availableDataSourceChoices}
            handleCardClick={handleDataSourceSelect}
            handleCardAccountClick={useHandleCardAccountClick}
            cardStyle={cardStyle}
            imgStyle={imgStyle}
          />
        );
      case 1:
        if (selectedDataSource === 'xls') {
          setHighlightNextButton(false);
          return (
            <XlsUploadPanel 
              onFileSelected={setSelectedFile} 
              checkFileExists={checkFileExists} 
            />
          );
        }
        if (selectedDataSource === 'csv') {
          setHighlightNextButton(false);
          return (
            <CsvUploadPanel 
              onFileSelected={setSelectedFile} 
              checkFileExists={checkFileExists} 
            />
          );
        }
        if (selectedDataSource === 'google') {
          if (selectedFile) {
            setSelectedFile(null);
          }
          if (openModal === true || openModalNew === true) {
            setOpenModal(false);
            setOpenModalNew(false);
          }
          if (selectedTableId) {
            setSelectedTableId("");
            setSelectedBaseId("");
          }
          setHighlightNextButton(false);
          return (
            <GoogleSheetsUploadPanel 
              onUrlChange={handleGoogleSheetUrlChange} 
            />
          );
        }
        if (selectedDataSource === 'airtable') {
          if (selectedFile) {
            setSelectedFile(null);
          }
          if (openModal === true || openModalNew === true) {
            setOpenModal(false);
            setOpenModalNew(false);
          }
          if (googleSheetUrl) {
            setGoogleSheetUrl("");
          }
          setHighlightNextButton(false);
          return <AirtablePanel bases={airtableBases} />;
        }
        break;
      case 2:
        setHighlightNextButton(false);
        return (
          <ActionSelector 
            actionChoices={userActionTypes} 
            handleActionSelect={handleActionSelect} 
          />
        );
      case 3:
        if (actionType === 'gmail.send-email') {
          if (!actionConfig.columns || actionConfig.columns.length === 0) {
            navigate('/signin');
            return null; // Returning null to avoid rendering anything before redirect
          }
          setHighlightNextButton(false);
        
          return (
            <ActionConfig 
              columns={actionConfig.columns} 
              userEmail={user.email} 
            />
          );
        } else {
          setHighlightNextButton(false);
          return (
            <TwilioActionConfig 
              columns={actionConfig.columns} 
            />
          );
        }
      case 4:
        if (actionType === 'gmail.send-email') {
          setHighlightNextButton(false);
          return (
            <PreviewPanel 
              emails={previewEmails} 
              row_count={actionConfig.row_count} 
            />
          );
        } else {
          setHighlightNextButton(false);
          return (
            <PreviewSmsPanel 
              smss={previewEmails} 
              row_count={actionConfig.row_count} 
            />
          );
        }
      default:
        return <div>Unrecognized step</div>;
    }
  };

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Slide
            key={currentStep}
            direction="left"
            in={true}
            mountOnEnter
            unmountOnExit
          >
            <div>{renderCurrentStep()}</div>
          </Slide>
        </Grid>
        <Grid item>
          {currentStep > 0 && (
            <Button 
              onClick={goToPreviousStepWithNavigation} 
              style={{ color: 'black' }} 
              startIcon={<MdArrowBackIos />} 
              sx={{ ml: 3 }}
            >
              Back
            </Button>
          )}
          {currentStep > 0 && currentStep < 4 && currentStep != 2 && (
            <Button
              onClick={goToNextStepWithConditions}
              disabled={!isNextButtonEnabled()}
              endIcon={<MdArrowForwardIos />}
              style={{
                color: isNextButtonEnabled() ? 'black' : 'grey', // Grey when disabled
                backgroundColor: highlightNextButton && isNextButtonEnabled() ? 'rgb(252, 87, 47)' : 'transparent'
              }}
              sx={{ ml: 3 }}
            >
              Next
            </Button>
          )}
        </Grid>
      </Grid>
      <UploadDialog 
        openModal={openModal} 
        setOpenModal={setOpenModal} 
        uploadFile={uploadFile} 
        goToNextStep={goToNextStep} 
        selectedFile={selectedFile} 
      />
      <NewUploadDialog 
        openModal={openModalNew} 
        setOpenModal={setOpenModalNew} 
        uploadFile={uploadFile} 
        goToNextStep={goToNextStep} 
        selectedFile={selectedFile} 
      />
    </>
  );
}

export default NewCampaign;
