import React, { useState, useEffect } from "react";
import {
  LinearProgress,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TablePagination,
} from "@mui/material";
import { ProgressBar } from "../../../../../../styled-components/ProgressBar";
import { SubHeader } from "../../../../../../styled-components/Typography";
import { useJobDetails } from "../../../../../../contexts/JobDetailsContext";
import { useNavigate } from "react-router-dom";

const EmailProgressBar = ({
  totalItemsCount,
  statsSucceeded,
  statsFailed,
  jobId,
  actionType,
}) => {
  const navigate = useNavigate();
  const { enableRealTime, jobState } = useJobDetails();
  const [failedEmails, setFailedEmails] = useState([]);
  const [succeededItems, setSucceededItems] = useState(statsSucceeded);
  const [failedItems, setFailedItems] = useState(statsFailed);
  const [progress, setProgress] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [intervalDelay, setIntervalDelay] = useState(500); // Initial delay for polling
  const [retryCount, setRetryCount] = useState(0); // Retry count for exponential backoff

  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  const csrftoken = getCookie("csrftoken");

  const recordError = async (errorMessage, apiUrl, requestBody) => {
    try {
      const errorRecordUrl = "https://postsheet.com/api/error-record";
      await fetch(errorRecordUrl, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({
          error_message: errorMessage,
          api_url: apiUrl,
          request: requestBody,
        }),
      });
    } catch (err) {
      console.error("Failed to record the error:", err);
      navigate("/app/error", { state: { errorMessage: err.message } });
    }
  };

  useEffect(() => {
  
    let interval;
    const url = `https://postsheet.com/api/progress/${jobId}/`;

    const fetchProgress = async () => {
    
      try {
        const response = await fetch(url, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setSucceededItems(data.succeeded_items);
        setFailedItems(data.failed_items);

        setProgress(data.progress);
      
        setFailedEmails(data.failed_emails);
      

        if (data.progress >= 50) {
          setIntervalDelay(2000); // Increase polling interval after 50% progress
        }

        if (data.status !== "running" && data.status !== "initializing") {
          clearInterval(interval); // Stop polling when status changes
        }
      } catch (error) {
        recordError(error.message, url, null);
        setRetryCount((prev) => prev + 1);
        const backoffDelay = Math.min(1000 * 2 ** retryCount, 30000); // Exponential backoff, max 30s
        setIntervalDelay(backoffDelay);
        navigate("/app/error", { state: { errorMessage: error.message } });
      }
    };

    if (jobState === "running" || jobState === "initializing") {
    
      interval = setInterval(fetchProgress, intervalDelay);
    } else {
  
      fetchProgress(); // Fetch once if not running or initializing
    }

    return () => clearInterval(interval); // Clean up on unmount
  }, [intervalDelay, jobState, jobId, retryCount]);

  const handleRetry = (email) => {
    // Implement retry logic here
  };

  const handleDelete = (email) => {
    setFailedEmails(failedEmails.filter((e) => e !== email));
  };

  const retryAll = () => {
    // Implement logic to retry all failed emails
  };

  const deleteAll = () => {
    setFailedEmails([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h4">{succeededItems}</Typography>
          <Typography variant="subtitle1">Sent</Typography>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h4">{totalItemsCount}</Typography>
          <Typography variant="subtitle1">Total</Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ width: "100%", mr: 1 }}>
            <ProgressBar
              sx={{ width: "100%" }}
              variant="determinate"
              value={progress}
            />
          </Box>
          <div>{`${progress}%`}</div>
        </Box>
      </Box>

      {failedEmails.length > 0 && (
        <>
          <Box sx={{ textAlign: "center", marginTop: "1.5rem" }}>
            <Typography variant="h4">{failedEmails.length}</Typography>
            <Typography variant="subtitle1">Failed</Typography>
          </Box>
          <Box sx={{ mt: 4 }}>
            <SubHeader>Failed Items</SubHeader>
            {actionType === "twilio.send-sms" ? (
              // Table for 'twilio.send-sms' actionType
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>To Number</TableCell>
                    <TableCell>Twilio Error Code</TableCell>
                    <TableCell>Error Details</TableCell>
                    <TableCell>Twilio Error Details Url</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {failedEmails
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((sms, index) => (
                      <TableRow key={index}>
                        <TableCell>{sms.to_number}</TableCell>
                        <TableCell>{sms.error_code}</TableCell>
                        <TableCell>{sms.error_details}</TableCell>
                        <TableCell>
                          {sms.url_details ? (
                            <a
                              href={sms.url_details}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {sms.url_details}
                            </a>
                          ) : (
                            ""
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            ) : (
              // Default table for other actionTypes
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>To Address</TableCell>
                    <TableCell>To Name</TableCell>
                    <TableCell>Error</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {failedEmails
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((email, index) => (
                      <TableRow key={index}>
                        <TableCell>{email.to_address}</TableCell>
                        <TableCell>{email.to_name}</TableCell>
                        <TableCell>{email.error_message}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
            <TablePagination
              rowsPerPageOptions={[5, 7, 10]}
              component="div"
              count={failedEmails.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default EmailProgressBar;
