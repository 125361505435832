import React, { useEffect } from 'react';
import { Grid, Card, CardActionArea, CardMedia, Paper, Typography, Box, Link } from '@material-ui/core';
import { DataSourceTitle, H2, H3, SubHeader } from '../../../../../styled-components/Typography';
import { FaRegQuestionCircle } from "react-icons/fa";
import AddAccount from '../../../accounts/AddAccount/AddAccount';

function DataChoicePanels({ 
  userDataSourceChoices, 
  availableDataSourceChoices, 
  handleCardClick,
  handleCardAccountClick, 
  cardStyle, 
  imgStyle,
  formData 
}) {

  return (
    <Grid container spacing={4} style={{ padding: '22px' }}>
      <Paper style={{ width: '100%', padding: '20px' }}>
        <Grid item xs={12} style = {{width:'70%', marginLeft: '1vh'}}>
          <H2>New Campaign</H2>
          <SubHeader>Where is your data?</SubHeader>
          <br/>
          <br/>
          <Box sx={{ mt: 2, p: 2, backgroundColor: '#fff3cd', borderRadius: '4px' }}>
  <Typography sx={{ color: '#6c757d', fontWeight: 'bold' }}>
    This is a new version of postsheet. If you find any errors or have any suggestions, please send an email to support@postsheet.com
  </Typography>
</Box>

          <br/>
          <br/>
        </Grid>

        <Grid item container spacing={3}>
          {userDataSourceChoices.map(choice => (
            <Grid item xs={12} sm={6} md={3} key={choice.key} style={{marginBottom: '2vh'}}>
              <Card onClick={() => handleCardClick(choice.data_source_key, choice.key, choice.user_account_id)} style={cardStyle}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt={choice.title}
                    style={imgStyle}
                    image={`../../static/assets/images/data-sources/${choice.key}.svg`}
                  />
                  <DataSourceTitle>
                    {choice.title}
                  </DataSourceTitle>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>
      
      <Paper style={{ width: '100%', padding: '20px', marginTop: '20px' }}>
        <H3>More Data Sources</H3>
        
        
        {availableDataSourceChoices.length > 0 && (
          <>
          <Typography style={{ marginBottom: '20px' }}>Add an account to use these data sources:</Typography>
          <Grid container spacing={3}>
            {availableDataSourceChoices.map(accountType => (
              <Grid item xs={12} sm={6} md={3} key={accountType.key}>
                <Card style={cardStyle} onClick={() => handleCardAccountClick(accountType.key)}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt={accountType.title}
                      style={cardStyle}
                      image={`../../static/assets/images/accounts/${accountType.key}.svg`}
                    />
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
            {formData && <AddAccount formData={formData} />}
          </Grid>
          </>
        )}
        
        <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
          <FaRegQuestionCircle style={{ marginRight: 8 }} />
          <Typography>
            Need support for other data sources?{' '}
            <Link style={{textDecoration: 'underline',color: 'black', fontFamily: "Helvetica Neue", fontWeight: '800'}} href="/app/support?message=I%20need%20support%20for..." target="_blank">
              Let us know
            </Link>
          </Typography>
        </div>
      </Paper>
    </Grid>
  );
}

export default DataChoicePanels;
