// ProgressCard.js
import React, { useEffect } from 'react';
import { Paper, IconButton } from '@mui/material';
import { FaRegPlayCircle } from "react-icons/fa";
import { GiCancel } from "react-icons/gi";
import { FaRegPauseCircle } from "react-icons/fa";
import { H2, SubHeader } from '../../../../../../styled-components/Typography';
import EmailProgressBar from './ProgressBar';
import { useJobDetails } from '../../../../../../contexts/JobDetailsContext';
import { useJobStatePolling } from '../../../../../../pollings/JobStatePolling';

function ProgressCard({ totalItems, jobName, jobId, jobState: initialJobState, uid, statsSucceeded, statsFailed, actionType }) {
    const { pauseCampaign, cancelCampaign, runCampaign, enableRealTime, isButtonPause } = useJobDetails();
    const jobState = useJobStatePolling(jobId, enableRealTime, initialJobState);
    
    useEffect(() => {
   
        // Additional actions can be performed here based on the new jobState
    }, [jobState]); 

    return (
        <Paper elevation={3} sx={{ p: 4, m: 2, position: 'relative' }}>
         
            {/* Remove this line if not needed */}
            <EmailProgressBar  totalItemsCount={totalItems} uid={uid} statsSucceeded={statsSucceeded} statsFailed={statsFailed} jobId={jobId} actionType={actionType} />

            {/* Icons in the upper right corner */}
            <div style={{ position: 'absolute', top: 16, right: 16 }}>
                {(jobState === 'running' || jobState === 'initializing' || jobState === 'scheduled') && (
                    <IconButton onClick={async () => await pauseCampaign(jobId)}>
                        <FaRegPauseCircle />
                    </IconButton>
                )}
         {(jobState === 'paused' || jobState === 'completed_with_fail') && jobState !== 'scheduled' && (
    <IconButton onClick={() => runCampaign(jobId)}>
        <FaRegPlayCircle />
    </IconButton>
)}
                {jobState !== 'cancelled' && (
                    <IconButton onClick={() => cancelCampaign(jobId)}>
                        <GiCancel />
                    </IconButton>
                )}
            </div>
        </Paper>
    );
}

export default ProgressCard;
