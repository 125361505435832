import React, { useEffect } from 'react';
import ProgressCard from './ProgressCard';
import ActionDetails from './ActionDetails';
import TemplateDetails from './TemplateDetails';

const JobDetails = ({ job, totalItems, uid }) => {
    

    return (
        <>
            <ProgressCard 
                totalItems={totalItems} 
             
                jobName={job.name} 
                jobId={job.id} 
                jobState={job.state} 
                uid={uid} 
                statsSucceeded={job.stats_succeeded} 
                statsFailed={job.stats_failed}
                actionType = {job.action_type}

                
            />
            <ActionDetails 
                dataSourceAccountId={job.data_source_account_id} 
                actionAccountId={job.action_account_id} 
                dataSourceTypeKey = {job.data_source_type}
            />
            <TemplateDetails jobId={job.id}/>
        </>
    );
}

export default JobDetails;
