import React, { useEffect, useState } from "react";
import { Grid, Paper, Modal, Button, Typography , Box, CircularProgress} from "@material-ui/core";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useProfile } from "../../../contexts/ProfileContext";
import AddAccountModal from "./AddAccountModal";
import {
  H2,
  SemiBoldUnderlined,
  TextSimple,
  PrimarySemiBold,
  SemiBold,
} from "../../../styled-components/Typography";
import { useAuth } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

function Profile() {
  const { profileData, loading, error } = useProfile();
  const { id } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmUnsubscribeOpen, setIsConfirmUnsubscribeOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenConfirmUnsubscribe = () => {
    setIsConfirmUnsubscribeOpen(true);
  };

  const handleCloseConfirmUnsubscribe = () => {
    setIsConfirmUnsubscribeOpen(false);
  };

  const handleOpenSuccessModal = () => {
    setIsSuccessModalOpen(true);
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
    navigate('/');  // Redirect to the home page after closing the success modal
  };

  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  const csrftoken = getCookie("csrftoken");

  const handleProceed = () => {

    window.location.href = `https://postsheet.com/accounts/google/login/?state=${id}`; 
  };
  const recordError = async (errorMessage, apiUrl, requestBody) => {
    try {
      const errorRecordUrl = 'https://postsheet.com/api/error-record';
      await fetch(errorRecordUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({
          error_message: errorMessage,
          api_url: apiUrl,
          request: requestBody,
        }),
      });
    }catch (err) {
      console.error('Failed to record the error:', err);
      navigate('/app/error', { state: { errorMessage: err.message } });
    }
  };
  
  const handleBillingPortalRedirect = async () => {
    try {
      const response = await fetch('https://postsheet.com/billing', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,  // Make sure csrftoken is defined properly
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data.url) {
          window.location.href = data.url; // Redirect to the billing portal URL
        } else {
          console.error('Invalid URL from billing portal response.');
        }
      } else {
        console.error('Failed to create billing portal session.');
      }
    } catch (error) {
      console.error('Error during billing portal redirect:', error);
      recordError(error.message, 'https://postsheet.com/billing', null);
      navigate('/app/error', { state: { errorMessage: error.message } });
    }
  };

  const handleUpgradeNow = () => {
    navigate('/pricing'); // Redirect to the pricing page
  };

  const handleCancel = () => {

    handleCloseModal();
  };

  const handleUnsubscribe = async () => {
    try {
      const response = await fetch('https://postsheet.com/api/unsubscribe', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
      });

      if (response.ok) {
        handleCloseConfirmUnsubscribe();
        handleOpenSuccessModal();
      } else {
        console.error('Failed to unsubscribe.');
      }
    } catch (error) {
      console.error('Error during unsubscribe:', error);
    }
  };

  if (loading)   return (
    <Paper style={{ width: "100%", overflow: "hidden", padding: '1rem' }}>
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    </Paper>
  );
  

  return (
    <>
      <Grid container spacing={4} style={{ padding: "22px" }}>
        <Paper style={{ width: "100%", padding: "20px" }}>
          <H2>Profile</H2>
          <br />
          <br />
          <TextSimple>
            You are signed in as{" "}
            <SemiBoldUnderlined>{profileData.user.user.email}</SemiBoldUnderlined>
          </TextSimple>
        </Paper>

        <Paper style={{ width: "100%", padding: "20px", marginTop: "20px" }}>
          <H2>Plan</H2>
          <br />
          <br />
          <TextSimple>
            You are currently on the{" "}
            <PrimarySemiBold>
  {profileData.plan.billing_interval === 'lifetime' ? 'Lifetime' : profileData.product.name} 
</PrimarySemiBold> plan.
          </TextSimple>
          <TextSimple>
            You have used{" "}
            <SemiBold>
              {profileData.total_job_item_count}/{profileData.product.quota}
            </SemiBold>{" "}
            sends of your monthly quota.
          </TextSimple>
          <br />
          <SemiBold onClick={handleUpgradeNow} style={{ cursor: 'pointer' }}>
            Need more sends? <PrimarySemiBold>Upgrade Now</PrimarySemiBold>.
          </SemiBold>
          <div onClick={handleBillingPortalRedirect} style={{ display: "flex", alignItems: "center", cursor: 'pointer' }}>
            <SemiBoldUnderlined>Billing Portal</SemiBoldUnderlined>
            <FaExternalLinkAlt style={{ marginLeft: "0.5rem" }} />
          </div>
        </Paper>

        <Paper style={{ width: "100%", padding: "20px", marginTop: "20px" }}>
          <H2>Subscription</H2>
          <br />
          <br />
          <TextSimple>
            To view your invoices, manage your cards, or cancel your subscription,
            please use the Billing Portal.
          </TextSimple>
          <TextSimple>
  If you want to unsubscribe, click{' '}
  <span 
    onClick={handleOpenConfirmUnsubscribe} 
    style={{ cursor: 'pointer', textDecoration: 'underline' }}
  >
    here
  </span>.
</TextSimple>
        </Paper>
      </Grid>

      <Modal open={isConfirmUnsubscribeOpen} onClose={handleCloseConfirmUnsubscribe}>
        <Paper style={{ padding: "20px", margin: "100px auto", maxWidth: "500px" }}>
          <Typography variant="h6">Are you sure you want to unsubscribe? All your campaigns, files and templates will be deleted.</Typography>
          <br/>
          <Button onClick={handleUnsubscribe} color="primary" variant="contained" style={{ marginRight: "10px" }}>
            Yes
          </Button>
          <Button onClick={handleCloseConfirmUnsubscribe} color="secondary" variant="contained">
            No
          </Button>
        </Paper>
      </Modal>

      <Modal open={isSuccessModalOpen} onClose={handleCloseSuccessModal}>
        <Paper style={{ padding: "20px", margin: "100px auto", maxWidth: "500px" }}>
          <Typography variant="h6">
            You have successfully unsubscribed to PostSheet! Hope to see you soon. If you have any suggestions, send them to postsheet@support.com.
          </Typography>
          <br/>
          <Button onClick={handleCloseSuccessModal} color="primary" variant="contained" style={{ marginTop: "20px" }}>
            Close
          </Button>
        </Paper>
      </Modal>

      <AddAccountModal
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        handleProceed={handleProceed}
        handleCancel={handleCancel}
      />
    </>
  );
}

export default Profile;
